import _ from 'lodash';

const DateTimeToDate12h = {
	fromValue: (val) => {
		if (val == null) return val;
		const temp = new Date(val.replace('Z', '.000Z'));
		return new Date(
			Date.UTC(temp.getFullYear(), temp.getMonth(), temp.getDate())
		).toISOString();
	},
	toValue: (val) => {
		if (val == null) return val;
		const temp = new Date(val);
		const ret = new Date(
			Date.UTC(temp.getFullYear(), temp.getMonth(), temp.getDate(), 0, 0, 0)
		);
		ret.setHours(12);
		const real_ret = ret.toISOString().replace('.000Z', 'Z');
		return real_ret;
	},
};

const kgToGram = {
	toValue: (value) => {
		return value / 1000;
	},
	fromValue: (value) => {
		return value * 1000;
	},
};

const factorToPercent = {
	toValue: (value) => {
		return value / 100 + 1;
	},
	fromValue: (value) => {
		if (value === 1) return 0;
		return (value - 1) * 100;
	},
};

const applyConverter = (value, converterList, direction, props) => {
	if (converterList.length === 0) return value;
	let workingConverter = null;
	let funcName = null;
	if (direction === 'TO') {
		workingConverter = _.cloneDeep(converterList);
		funcName = 'toValue';
	} else if (direction === 'FROM') {
		workingConverter = _.cloneDeep(converterList).reverse();
		funcName = 'fromValue';
	}
	let newValue = value;
	workingConverter.map((converter) => {
		const func = _.get(converter, funcName, (a) => a);
		newValue = func(newValue, props);
	});
	return newValue;
};

const convertToValue = (value, converters) => {
	let ret = value;
	converters.map((c) => {
		ret = c.toValue(ret);
	});
	return ret;
};

const convertFromValue = (value, converters) => {
	let ret = value;
	converters.map((c) => {
		ret = c.fromValue(ret);
	});
	return ret;
};

const currencyToStringMapping = {
	'€': 'EUR',
	$: 'USD',
	'£': 'GBP',
};

const stringToCurrencyMapping = {
	EUR: '€',
	USD: '$',
	GBP: '£',
};
const currencySign = {
	toValue: (currency) => {
		return _.get(currencyToStringMapping, currency, '');
	},
	fromValue: (currency) => {
		return _.get(stringToCurrencyMapping, currency, '');
	},
};

const optionalValue = (params) => {
	return {
		toValue: (val) => {
			if (val === params.optionalEmpty) {
				return _.get(params, 'optionalValue', null);
			} else {
				return val;
			}
		},
		fromValue: (val) => {
			return val;
		},
	};
};

export {
	DateTimeToDate12h,
	applyConverter,
	kgToGram,
	convertFromValue,
	convertToValue,
	factorToPercent,
	currencySign,
	optionalValue,
	stringToCurrencyMapping,
	currencyToStringMapping,
};
