/* eslint-disable-line */
import React, { useCallback, useContext } from 'react';
import TagManager from 'react-gtm-module';
import { MuiCookie } from '@lsoft/mui';
import { ssrRender } from '../../app/ShopApp';
import { getLsConfig } from '@lsoft/shared/nextjs/getLsConfig';

const { runtimeConfig } = getLsConfig();
const { NEXT_PUBLIC_GTM_CONTAINER_ID: gtm_id } = runtimeConfig;

const debug_log = false;
export const GTMProviderContext = React.createContext(null);
GTMProviderContext.displayName = 'GTMProviderContext';

export const GTMProvider = ({ children }) => {
	const { marketingCookies } = useContext(MuiCookie.Context);
	const enabled = marketingCookies && !ssrRender;

	if (!ssrRender) {
		if (debug_log) console.log('Initialize Google Tag Manager');
		// noinspection JSUnresolvedFunction
		TagManager.initialize({
			gtmId: gtm_id,
		});
	}

	const user_sign_up = () => {
		const dataLayer = {
			event: 'sign_up',
		};
		if (debug_log) console.log('user_sign_up', dataLayer);
		push_to_data_layer(dataLayer);
	};

	const newsletter_sign_up = () => {
		const dataLayer = {
			event: 'newsletter_sign_up',
		};
		if (debug_log) console.log('newsletter_sign_up', dataLayer);
		push_to_data_layer(dataLayer);
	};

	const push_to_data_layer = useCallback(
		(dataLayer) => {
			/** According to the documentation previous dataLayer ecommerce object
			 * should be cleared before pushing again.
			 * https://developers.google.com/tag-manager/enhanced-ecommerce*/

			if (enabled) {
				// noinspection JSUnresolvedFunction
				TagManager.dataLayer({ ecommerce: null });
				// noinspection JSUnresolvedFunction
				TagManager.dataLayer({ dataLayer });
			}
		},
		[TagManager, enabled],
	);

	const value = {
		user_sign_up,
		newsletter_sign_up,
		push_to_data_layer,
	};

	return (
		<GTMProviderContext.Provider value={value}>
			{children}
		</GTMProviderContext.Provider>
	);
};
