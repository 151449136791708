import _ from 'lodash';
import { erp_promotions_put } from '../../backend/erp/promotions';
import { render_promotions_get } from '../../backend/render/promotions';
import { getLsConfig } from '../../nextjs/getLsConfig';
import { erpPromotionsRead } from '../../api/promotions';

const { runtimeConfig } = getLsConfig();

const org_id = _.get(runtimeConfig, 'NEXT_PUBLIC_ORG_ID');
const render_product_version = _.get(
	runtimeConfig,
	'NEXT_PUBLIC_RENDER_PRODUCT_VERSION',
);
const render_product_class = _.get(
	runtimeConfig,
	'NEXT_PUBLIC_RENDER_PRODUCT_CLASS',
);
export const check_now_in_timeframe = (start_date, end_date) => {
	if (_.includes([start_date, end_date], null)) return false;
	// Get UTC now
	const now = new Date().toISOString();
	// Parse dates
	const now_parsed = Date.parse(now);
	const start_date_parsed = Date.parse(start_date);
	const end_date_parsed = Date.parse(end_date);
	// Compare dates
	const discount_start_date_passed = now_parsed - start_date_parsed > 0;
	const discount_end_date_passed = end_date_parsed - now_parsed < 0;
	// Return
	return discount_start_date_passed && !discount_end_date_passed;
};

export const get_promotions = async ({
	view,
	active,
	future,
	without_empty = false,
}) => {
	const erpPromotions = await erpPromotionsRead({ view: view, active, future });
	if (without_empty) {
		const renderPromotions = await render_promotions_get({
			org_id,
			render_product_class: render_product_class,
			render_product_version: render_product_version,
		});
		await Promise.all([erpPromotions, renderPromotions]);
		const r = format_render_promotions(renderPromotions);
		return get_intersection_of_promotions(r, erpPromotions);
	} else {
		return erpPromotions;
	}
};

const format_render_promotions = (promotions) => {
	const list = [];
	Object.values(promotions).map((promotion) => {
		list.push(promotion['promotion_id'][0]);
	});
	return list;
};

const get_intersection_of_promotions = (render_promotions, erp_promotions) => {
	if (erp_promotions === null) return null;
	const currentPromotions = {};

	Object.values(render_promotions).map((promotion) => {
		if (promotion in erp_promotions) {
			currentPromotions[promotion] = erp_promotions[promotion];
		}
	});
	return currentPromotions;
};
