import { getLsConfig } from '@shared/nextjs/lsconfig';
import { TDictionary } from '@shared/types';
import _ from 'lodash';

type TRequestMethod =
  | 'get'
  | 'post'
  | 'put'
  | 'patch'
  | 'delete'
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'PATCH'
  | 'DELETE';

export type TLsRestClientParams = Record<string, any>;
export type TLsRestClientParamsListMode = 'comma_separated' | 'fastapi_multi';
export type TLsRestClientBody = Record<string, any> | string;
export type TLsRestClientConfig = {
  baseUrl?: string;
  basePath?: string;
  jsonResult?: boolean;
  url?: string;
  headers?: Record<string, any>;
  method?: TRequestMethod;
  params?: TLsRestClientParams;
  body?: TLsRestClientBody;
  options?: Record<string, any>;
  cache?: string;
  paramsListMode?: TLsRestClientParamsListMode;
};

export type TLsRestClientCall<T> = { config?: TLsRestClientConfig } & T;

type TLsRestClientInfo = {
  name: string;
  config?: TLsRestClientConfig;
};

type TLsRestClientCache = TDictionary<TLsRestClientInfo>;

declare global {
  // noinspection ES6ConvertVarToLetConst
  var globalLsRestClientCache: TLsRestClientCache;
}

export const getLsRestClientCache = () => {
  if (typeof globalLsRestClientCache === 'undefined')
    global.globalLsRestClientCache = {};
  return globalLsRestClientCache;
};

export const getLsRestClientInfo = (
  name: string = 'default',
): TLsRestClientInfo => {
  const cache = getLsRestClientCache();
  if (name in cache) return cache[name];
  if ('default' in cache) return cache['default'];
  // No default, create it from lsconfig
  const lsConfig = getLsConfig();
  // noinspection JSUnresolvedReference
  const baseUrl = lsConfig?.apiBaseUrl ?? '';

  // noinspection JSUnresolvedReference
  const basePath = lsConfig?.apiBasePath ?? '/api';
  setLsRestClientInfo({ name: 'default', config: { baseUrl, basePath } });
  return cache['default'];
};

const getClient = (name: string = 'default') => {
  const clientInfo = getLsRestClientInfo(name);
  return makeClient(clientInfo);
};

const defaultConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
  cache: 'no-cache',
  jsonResult: true,
};

export const checkLsRestClientError = async (response: Response) => {
  if (response.ok) return;
  // try to get error details from json.
  let detail = undefined;
  try {
    const jsonError = await response.json();
    detail = jsonError?.detail;
  } catch {}

  if (detail) {
    throw new LsApiError({
      statusCode: response.status,
      errorClass: detail.ERROR_CLASS,
      error: detail.ERROR,
      traceback: detail.TRACEBACK,
      payload: detail?.PAYLOAD,
    });
  }
  let textError = '';
  // no details make basic error
  try {
    textError = await response.text();
  } catch {}
  throw new LsApiError({
    statusCode: response.status,
    error: textError,
  });
};

const makeClient = (clientInfo: TLsRestClientInfo): Function => {
  return async (config: TLsRestClientConfig): Promise<Response> => {
    const completeConfig = _.merge(
      {},
      defaultConfig,
      clientInfo.config,
      config,
    );
    completeConfig.method = (
      completeConfig.method ?? 'get'
    ).toUpperCase() as TRequestMethod;

    if (typeof completeConfig?.body === 'object') {
      completeConfig.body = JSON.stringify(completeConfig.body);
    }

    const fullUrl = buildUrl(completeConfig);
    // console.log('fu', fullUrl);
    // console.log('cc', completeConfig);
    const fetchOptions = _.omit(
      completeConfig,
      'baseUrl',
      'basePath',
      'params',
      'jsonResult',
    );
    // console.log('fo', fetchOptions);
    try {
      const promiseResponse = fetch(fullUrl, fetchOptions as RequestInit);
      if (completeConfig.jsonResult) {
        const response = await promiseResponse;
        await checkLsRestClientError(response);
        return await response.json();
      }
      return promiseResponse;
    } catch (e: any) {
      const newError = new LsApiBaseError(e.name, completeConfig);
      newError.message = e.message;
      newError.stack = e.stack;
      throw newError;
    }
  };
};

const setLsRestClientInfo = (clientInfo: TLsRestClientInfo) => {
  clientInfo.config ??= {};
  const cache = getLsRestClientCache();
  cache[clientInfo.name] = clientInfo;
};

export const setBearerToken = (name: string, token: string) => {
  const clientInfo = getLsRestClientInfo(name);
  clientInfo.config = _.merge(
    {},
    _.set(
      clientInfo.config as object,
      'headers.Authorization',
      'Bearer ' + token,
    ),
  );
  setLsRestClientInfo(clientInfo);
};

export const buildUrl = (config: TLsRestClientConfig) => {
  let ret = config.baseUrl ?? '';
  ret += config.basePath ?? '';
  const url = config.url ?? '';
  const originalParams = _.cloneDeep(config.params as Record<string, unknown>);
  const paramsListMode = config.paramsListMode ?? 'fastapi_multi';

  const params: Record<string, any> = Object.entries(
    originalParams ?? {},
  ).reduce(
    (d, [k, v]) => {
      // d[k] = `${v}`;
      d[k] = v;
      return d;
    },
    {} as Record<string, any>,
  );

  const newPath = url.replace(/{(\w+)}/g, (_match, param) => {
    const value = _.get(params, param);
    _.unset(params, param);
    return value;
  });
  ret += newPath;

  if (Object.keys(params).length !== 0) {
    const urlParams = new URLSearchParams({});
    Object.entries(params).map(([key, value]) => {
      switch (paramsListMode) {
        case 'fastapi_multi':
          if (_.isArray(value)) {
            value.map((listValue) => {
              urlParams.append(key, listValue);
            });
          } else {
            urlParams.append(key, value);
          }
          break;
        case 'comma_separated':
          urlParams.append(key, value);
          break;
      }
    });
    ret += '?' + urlParams.toString();
  }
  return ret;
};

export type TLsApiErrorInfo = {
  statusCode?: number;
  errorClass?: string;
  error?: string;
  traceback?: string;
  payload?: TDictionary<unknown>;
};

export class LsApiBaseError extends Error {
  public statusCode: number | undefined;
  public clientConfig: TLsRestClientConfig | undefined;

  constructor(
    error: string | undefined,
    config: TLsRestClientConfig | undefined,
  ) {
    super(error);
    this.name = error as string;
    this.clientConfig = config;
  }
}

export class LsApiError extends LsApiBaseError {
  public statusCode: number | undefined;
  public payload: TDictionary<unknown> | undefined;
  public traceback: string | undefined;

  constructor(
    info: TLsApiErrorInfo,
    config: TLsRestClientConfig | undefined = undefined,
  ) {
    super(info.error, config);
    this.name = info?.errorClass ?? 'UnknownError';
    this.message = info.error ?? '';
    this.statusCode = info.statusCode;
    this.payload = info.payload;
    this.traceback = info.traceback;
  }
}

const lsrestclient = {
  client: getClient,
  register: setLsRestClientInfo,
  setBearerToken: setBearerToken,
};

export default lsrestclient;
