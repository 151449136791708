const { getLsConfig } = require('./getLsConfig');
const { getGlobalLsConfig } = require('./getGlobalLsConfig');
const { flatLsConfigServer, flatLsConfigClient } = require('./flatLsConfig');

module.exports = {
	getLsConfig,
	getGlobalLsConfig,
	flatLsConfigServer,
	flatLsConfigClient,
};
