import { MuiWebapi } from '@lsoft/mui';
import _ from 'lodash';
import { extract_data_for_mui_pagination_view } from '../../functions/extract_data_for_mui_pagination_view';
import { getLsConfig } from '../../nextjs/getLsConfig';

const debug_log = false;

const { runtimeConfig } = getLsConfig();

const render_product_class = _.get(
	runtimeConfig,
	'NEXT_PUBLIC_RENDER_PRODUCT_CLASS',
);
const render_product_version = _.get(
	runtimeConfig,
	'NEXT_PUBLIC_RENDER_PRODUCT_VERSION',
);
const org_id = _.get(runtimeConfig, 'NEXT_PUBLIC_ORG_ID');

export const render_product_load = async ({ sku, view }) => {
	const ret = await MuiWebapi.get().lsfastapi.render.paths[
		'/render/rendered_product'
	].get({
		sku,
		view,
		org_id,
		version: render_product_version,
		render_class: render_product_class,
	});
	return _.get(ret, 'data');
};

export const render_products_get = async (options) => {
	const { view, skip, limit, filter, sort } = options;
	// hier chunken
	const res = await MuiWebapi.get().lsfastapi.render.paths[
		'/render/rendered_products'
	].put(
		{
			org_id,
			version: render_product_version,
			render_class: render_product_class,
			view,
			skip,
			sort,
			limit: _.isNil(limit) ? 50 : limit,
		},
		{
			filter,
		},
	);
	return res;
};

export const rendered_products_pagination_view = async (options) => {
	const res = await render_products_get(options);
	if (debug_log)
		console.log('rendered_products_pagination_view options', options);
	if (debug_log) console.log('rendered_products_pagination_view res', res);
	return extract_data_for_mui_pagination_view(res);
};

export const render_products_search_get = async (options) => {
	const { view, skip, limit, query, sort } = options;
	const result = await MuiWebapi.get().lsfastapi.render.paths[
		'/render/rendered_products/search'
	].put(
		{
			org_id,
			version: render_product_version,
			render_class: render_product_class,
			view,
			skip,
			sort,
			limit: _.isNil(limit) ? 50 : limit,
		},
		{
			query,
		},
	);
	return _.get(result, 'data');
};

export const gift_finder_get_group_tags = async ({
	group_tags,
	price_start,
	price_end,
}) => {
	const result = await MuiWebapi.get().lsfastapi.render.paths[
		'/render/rendered_products/gift_finder/1'
	].put(
		{},
		{
			org_id,
			version: render_product_version,
			render_class: render_product_class,
			group_tags,
			price_start,
			price_end,
		},
	);
	return _.get(result, 'data');
};

export const gift_finder_get_pagination_view = async ({
	skip = 0,
	limit = 24,
	group_tags,
	price_start,
	price_end,
	selected_group_tags,
}) => {
	const result = await MuiWebapi.get().lsfastapi.render.paths[
		'/render/rendered_products/gift_finder/2'
	].put(
		{},
		{
			org_id,
			version: render_product_version,
			render_class: render_product_class,
			group_tags,
			price_start,
			price_end,
			selected_group_tags,
			limit,
			skip,
		},
	);
	return _.get(result, 'data');
};
