import theme from './src/styles/theme';
import { makeSearchPlugin } from './src/components/ShopSearch/Search';
import { getLsConfig } from '@lsoft/shared/nextjs/getLsConfig';

const { runtimeConfig } = getLsConfig();

export const appConfig = {
	availableApplicationModules: [],
	theme: theme,
	orgId: runtimeConfig.NEXT_PUBLIC_ORG_ID,
	appName: runtimeConfig.NEXT_PUBLIC_APP_NAME,
	appTitle: runtimeConfig.NEXT_PUBLIC_APP_TITLE,
	appVersion: runtimeConfig.NEXT_PUBLIC_APP_VERSION,
	cdsBaseUrl: runtimeConfig.NEXT_PUBLIC_CDS_BASEURL,
	searchPlugin: makeSearchPlugin(),
	sentry: {
		dsn: runtimeConfig.NEXT_PUBLIC_SENTRY_DSN,
		environment: runtimeConfig.NEXT_PUBLIC_APP_NAME,
		release: runtimeConfig.NEXT_PUBLIC_APP_VERSION,
		// enabled: env.NODE_ENV === 'production',
		enabled: true,
	},
	mailingList: runtimeConfig.NEXT_PUBLIC_MAILING_LIST,
	placesId: runtimeConfig.NEXT_PUBLIC_GOOGLE_PLACES_ID,
	mollieRedirectUrl: runtimeConfig.NEXT_PUBLIC_MOLLIE_REDIRECT_BASEURL,
	MAINTENANCE_MODE: runtimeConfig.NEXT_PUBLIC_MAINTENANCE_MODE,
	SHOP_MODE: runtimeConfig.NEXT_PUBLIC_SHOP_MODE,
};
