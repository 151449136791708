import _ from 'lodash';
import { TDictionary } from '@shared/types';
import {
  TPaginationData,
  TPaginationParameter,
  TPaginationResponse,
} from '@shared/types/pagination';
import {
  TLsRestClientBody,
  TLsRestClientConfig,
  TLsRestClientParams,
} from '@shared/lsrestclient/index';

export const paginationDefaults: TPaginationParameter = {
  limit: 20,
  skip: 0,
};

export const applyDefaultPaginationParameters = (
  parameters: TPaginationParameter,
  defaultParameter: TPaginationParameter = paginationDefaults,
): TPaginationParameter => {
  return _.omitBy(
    {
      sort: parameters?.sort ?? defaultParameter?.sort,
      filter: parameters?.filter ?? defaultParameter?.filter,
      view: parameters?.view ?? defaultParameter?.view,
      skip: parameters?.skip ?? defaultParameter?.skip,
      limit: parameters?.limit ?? defaultParameter?.limit,
    },
    _.isNil,
  );
};

export const fromUrlPaginationParameter = (
  searchParams: URLSearchParams | TDictionary<string>,
  defaultParameter: TPaginationParameter = paginationDefaults,
): TPaginationParameter => {
  // if searchParams is a dictionary, create new URLSearchParams from it
  const sp =
    searchParams instanceof URLSearchParams
      ? searchParams
      : new URLSearchParams(searchParams);

  const sort = sp.get('sort') ?? undefined;
  const filterString = sp.get('filter') ?? undefined;
  const filter = filterString ? JSON.parse(filterString) : undefined;
  const skip = sp.get('skip') ? parseInt(sp.get('skip')!) : undefined;
  const limit = sp.get('limit') ? parseInt(sp.get('limit')!) : undefined;

  return applyDefaultPaginationParameters(
    { sort, filter, skip, limit },
    defaultParameter,
  );
};

export const pickPaginationParameter = (
  data: TDictionary<unknown> | URLSearchParams,
): TPaginationParameter => {
  if (data instanceof URLSearchParams) {
    data = Object.fromEntries(data.entries());
  }
  return _.pick(
    data,
    'skip',
    'limit',
    'sort',
    'filter',
    'view',
  ) as TPaginationParameter;
};

export const pickPaginationParameterParams = (
  data: TDictionary<unknown>,
): TPaginationParameter => {
  return _.pick(data, 'skip', 'limit', 'sort', 'view') as TPaginationParameter;
};

export const pickPaginationParameterBody = (
  data: TDictionary<unknown>,
): TPaginationParameter => {
  return _.pick(data, 'filter') as TPaginationParameter;
};

export const pickPaginationResponse = (
  data: TDictionary<unknown>,
): TPaginationResponse<unknown> => {
  return _.pick(
    data,
    'skip',
    'limit',
    'sort',
    'filter',
    'view',
    'max_results',
  ) as TPaginationResponse<unknown>;
};

export const pickPaginationData = (
  data: TDictionary<unknown>,
): TPaginationData<unknown> => {
  return _.pick(data, 'data', 'ids') as TPaginationData<unknown>;
};

export const modPaginationParameter = (
  paginationParameter: TPaginationParameter,
  data: TPaginationParameter,
): TPaginationParameter => {
  return _.assign({}, paginationParameter, data);
};

export const sortedPaginationData = (
  data: TPaginationData<unknown>,
): unknown[] => {
  return data.ids.map((id) => data.data[id]);
};

export const withPaginationParameters = (
  clientParams: TLsRestClientConfig,
  funcParams: TDictionary<unknown>,
) => {
  //todo: use method to determine target for params
  const paginationParams = applyDefaultPaginationParameters(
    pickPaginationParameter(funcParams),
  );

  const params: TLsRestClientParams = _.assign(
    {},
    clientParams?.params ?? {},
    pickPaginationParameterParams(paginationParams),
  );

  const body: TLsRestClientBody = _.assign(
    {},
    clientParams?.body ?? {},
    pickPaginationParameterBody(paginationParams),
  );
  return _.assign({}, clientParams, { params, body });
};
