import _ from 'lodash';
import { getLsConfig } from '../../nextjs/getLsConfig';

const { runtimeConfig } = getLsConfig();
const org_id = _.get(runtimeConfig, 'NEXT_PUBLIC_ORG_ID');

export const escapeForElasticSearch = (search_string) => {
	return search_string.replace(/([+!(){}[\]^'"~*?:\\/-]|&&|\|\|)/g, '\\$1');
};

const get_field_string_with_org_id = (field_name) => {
	return `${field_name}_${org_id}`;
};

const get_wild_card_search_string = (search_string) => {
	const words = search_string.split(' ');

	let newstr = '';
	words.map((word) => {
		if (word.length >= 2) {
			newstr = newstr + '*' + word + '* ';
		}
	});
	return escapeForElasticSearch(newstr);
};

const query_string_exact_name_text = (append_list, search_string) => {
	append_list.push({
		query_string: {
			fields: ['name_text_de', 'name_text_en', 'name_text_fr'],
			boost: 20,
			default_operator: 'AND',
			query: escapeForElasticSearch(search_string),
		},
	});
};

const query_string_wildcard_name_text = (append_list, search_string) => {
	append_list.push({
		query_string: {
			fields: ['name_text_de', 'name_text_en', 'name_text_fr'],
			boost: 10,
			default_operator: 'AND',
			query: get_wild_card_search_string(search_string),
		},
	});
};
const query_string_wildcard_short_text = (append_list, search_string) => {
	append_list.push({
		query_string: {
			fields: ['short_text_de', 'short_text_en', 'short_text_fr'],
			boost: 5,
			default_operator: 'AND',
			query: get_wild_card_search_string(search_string),
		},
	});
};

const query_string_wildcard_long_text = (append_list, search_string) => {
	append_list.push({
		query_string: {
			fields: ['long_text_de', 'long_text_en', 'long_text_fr'],
			boost: 1,
			default_operator: 'AND',
			query: get_wild_card_search_string(search_string),
		},
	});
};

const query_string_sku = (append_list, search_string) => {
	const number = Number(search_string);
	if (isNaN(number)) return null;
	if (number > 1000000) return null;
	append_list.push({
		query_string: {
			boost: 30,
			fields: ['sku'],
			query: number,
		},
	});
};

const query_string_msku = (append_list, search_string) => {
	append_list.push({
		query_string: {
			fields: ['msku'],
			boost: 5,
			default_operator: 'AND',
			query: escapeForElasticSearch(search_string),
		},
	});
};

const query_string_mname = (append_list, search_string) => {
	append_list.push({
		query_string: {
			fields: ['mname'],
			boost: 3,
			default_operator: 'AND',
			query: escapeForElasticSearch(search_string),
		},
	});
};

const query_string_gtin = (append_list, search_string) => {
	append_list.push({
		query_string: {
			boost: 30,
			fields: ['gtin'],
			query: escapeForElasticSearch(search_string),
		},
	});
};

const query_string_exact_group_tags = (append_list, search_string) => {
	append_list.push({
		query_string: {
			fields: ['group_tags_de', 'group_tags_en', 'group_tags_fr'],
			boost: 20,
			default_operator: 'AND',
			query: escapeForElasticSearch(search_string),
		},
	});
};

const query_string_wildcard_group_tags = (append_list, search_string) => {
	append_list.push({
		query_string: {
			fields: ['group_tags_de', 'group_tags_en', 'group_tags_fr'],
			boost: 9,
			default_operator: 'AND',
			query: get_wild_card_search_string(search_string),
		},
	});
};

export const erp_search_query = (search_string, params) => {
	const should = [];
	query_string_sku(should, search_string);
	query_string_msku(should, search_string);
	query_string_mname(should, search_string);
	query_string_gtin(should, search_string);
	query_string_exact_name_text(should, search_string);
	query_string_wildcard_name_text(should, search_string);
	query_string_wildcard_short_text(should, search_string);
	query_string_wildcard_long_text(should, search_string);
	query_string_exact_group_tags(should, search_string);
	query_string_wildcard_group_tags(should, search_string);

	const sort = _.concat(
		[
			{
				[get_field_string_with_org_id('status')]: {
					order: 'desc',
				},
			},
		],
		params.sort,
	);
	if (!_.includes(sort, '_score')) sort.push('_score');

	const real_must = [
		{
			bool: {
				should,
			},
		},
	];

	const filter_musts = params.filter;
	if (filter_musts.length > 0) {
		real_must.push(...filter_musts);
	}

	const ret = {
		sort,
		query: {
			bool: {
				must: real_must,
			},
		},
	};
	return ret;
};

export const shop_search_query = (search_string, params) => {
	const should = [];
	query_string_sku(should, search_string);
	query_string_msku(should, search_string);
	query_string_mname(should, search_string);
	query_string_gtin(should, search_string);
	query_string_exact_name_text(should, search_string);
	query_string_wildcard_name_text(should, search_string);
	query_string_wildcard_short_text(should, search_string);
	query_string_wildcard_long_text(should, search_string);
	query_string_exact_group_tags(should, search_string);
	query_string_wildcard_group_tags(should, search_string);

	const must = params.filter;

	const sort = [];
	if (!_.includes(sort, '_score')) sort.push('_score');
	return {
		sort,
		query: {
			bool: {
				must: [
					{
						bool: {
							should,
							must,
						},
					},
				],
			},
		},
	};
};
